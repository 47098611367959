$primary-color: #006BB4;

#edit-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin-top: 5px;
  margin-bottom: 10px;
  
  .edit-control-btns {
    margin-top: 10px;
  }

  .edit-controls--children {
    margin-right: 10px;
  }

  .btn-cancel {
    color: $primary-color;
  }

  .btn-add {
    color: $primary-color;

  }

  .btn-save {
    color: $primary-color;
    background-color: #ffffff;
    border: 1px solid $primary-color;
    padding: 2px 13px;
    border-radius: 4px;
  }
}

.edit-container {
  display: flex;
  margin-bottom: 4px;

  & #edit-controls {
    margin-left: -100px;
  }
}

.edit-display {
  display: flex;
  position: relative;
  align-items: center;

  .edit-controls {
    position: absolute;
    right: 60px;
    margin-top: -5px;

    .euiFlexItem.euiFlexItem--flexGrowZero:last-of-type {
      margin-top: -2px;
    }
  }

  /**
    Personal Phone Styles
   */

  .edit-display--area-code {
    width: 100px;
  }

  .edit-display--phone-number {
    width: 150px;
  }

  .edit-display--phone-type {
    width: 70px;
  }
}


