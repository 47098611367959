.unsorted-list-row {
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.sort-option-disabled {
	opacity: 0.5;
	cursor: not-allowed;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.sort-option-group {
	padding-left: 24px;
}

// Fixes the issue where on drag the selected items moves outside the screen
.euiPanel.euiPanel--paddingMedium.euiPopover__panel.euiPopover__panel--bottom.euiPopover__panel-isOpen {
	transform: none;
}
