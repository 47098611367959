.m {
    &-0 {
        margin: 0px !important;
    }
    &-1 {
        margin: 0.25rem !important;
    }
    &-2 {
        margin: 0.5rem !important;
    }
    &-3 {
        margin: 0.75rem !important;
    }
    &-4 {
        margin: 1rem !important;
    }
    &-5 {
        margin: 1.25rem !important;
    }

    &-1x {
        margin: 1rem !important;
    }
    &-2x {
        margin: 2rem !important;
    }
    &-3x {
        margin: 3rem !important;
    }
    &-4x {
        margin: 4rem !important;
    }
    &-5x {
        margin: 5rem !important;
    }
}

.mx {
    &-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    &-0 {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    &-1 {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
    }
    &-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }
    &-3 {
        margin-left: 0.75rem !important;
        margin-right: 0.75rem !important;
    }
    &-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    &-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important;
    }

    &-1x {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    &-2x {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
    &-3x {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }
    &-4x {
        margin-left: 4rem !important;
        margin-right: 4rem !important;
    }
    &-5x {
        margin-left: 5rem !important;
        margin-right: 5rem !important;
    }
}

.my {
    &-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    &-0 {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    &-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    &-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    &-3 {
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
    &-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    &-5 {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    }

    &-1x {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    &-2x {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    &-3x {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    &-4x {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    &-5x {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
}

.mt {
    &-auto {
        margin-top: auto !important;
    }
    &-0 {
        margin-top: 0px !important;
    }
    &-1 {
        margin-top: 0.25rem !important;
    }
    &-2 {
        margin-top: 0.5rem !important;
    }
    &-3 {
        margin-top: 0.75rem !important;
    }
    &-4 {
        margin-top: 1rem !important;
    }
    &-5 {
        margin-top: 1.25rem !important;
    }

    &-1x {
        margin-top: 1rem !important;
    }
    &-2x {
        margin-top: 2rem !important;
    }
    &-3x {
        margin-top: 3rem !important;
    }
    &-4x {
        margin-top: 4rem !important;
    }
    &-5x {
        margin-top: 5rem !important;
    }
}

.mr {
    &-auto {
        margin-right: auto !important;
    }
    &-0 {
        margin-right: 0px !important;
    }
    &-1 {
        margin-right: 0.25rem !important;
    }
    &-2 {
        margin-right: 0.5rem !important;
    }
    &-3 {
        margin-right: 0.75rem !important;
    }
    &-4 {
        margin-right: 1rem !important;
    }
    &-5 {
        margin-right: 1.25rem !important;
    }

    &-1x {
        margin-right: 1rem !important;
    }
    &-2x {
        margin-right: 2rem !important;
    }
    &-3x {
        margin-right: 3rem !important;
    }
    &-4x {
        margin-right: 4rem !important;
    }
    &-5x {
        margin-right: 5rem !important;
    }
}

.ml {
    &-auto {
        margin-left: auto !important;
    }
    &-0 {
        margin-left: 0px !important;
    }
    &-1 {
        margin-left: 0.25rem !important;
    }
    &-2 {
        margin-left: 0.5rem !important;
    }
    &-3 {
        margin-left: 0.75rem !important;
    }
    &-4 {
        margin-left: 1rem !important;
    }
    &-5 {
        margin-left: 1.25rem !important;
    }

    &-1x {
        margin-left: 1rem !important;
    }
    &-2x {
        margin-left: 2rem !important;
    }
    &-3x {
        margin-left: 3rem !important;
    }
    &-4x {
        margin-left: 4rem !important;
    }
    &-5x {
        margin-left: 5rem !important;
    }
}

.mb {
    &-auto {
        margin-bottom: auto !important;
    }
    &-0 {
        margin-bottom: 0px !important;
    }
    &-1 {
        margin-bottom: 0.25rem !important;
    }
    &-2 {
        margin-bottom: 0.5rem !important;
    }
    &-3 {
        margin-bottom: 0.75rem !important;
    }
    &-4 {
        margin-bottom: 1rem !important;
    }
    &-5 {
        margin-bottom: 1.25rem !important;
    }

    &-1x {
        margin-bottom: 1rem !important;
    }
    &-2x {
        margin-bottom: 2rem !important;
    }
    &-3x {
        margin-bottom: 3rem !important;
    }
    &-4x {
        margin-bottom: 4rem !important;
    }
    &-5x {
        margin-bottom: 5rem !important;
    }
}

.p {
    &-0 {
        padding: 0px !important;
    }
    &-1 {
        padding: 0.25rem !important;
    }
    &-2 {
        padding: 0.5rem !important;
    }
    &-3 {
        padding: 0.75rem !important;
    }
    &-4 {
        padding: 1rem !important;
    }
    &-5 {
        padding: 1.25rem !important;
    }

    &-1x {
        padding: 1rem !important;
    }
    &-2x {
        padding: 2rem !important;
    }
    &-3x {
        padding: 3rem !important;
    }
    &-4x {
        padding: 4rem !important;
    }
    &-5x {
        padding: 5rem !important;
    }
}

.px {
    &-auto {
        padding-left: auto !important;
        padding-right: auto !important;
    }
    &-0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    &-1 {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }
    &-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    &-3 {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
    &-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    &-5 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }

    &-1x {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    &-2x {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
    &-3x {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
    &-4x {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }
    &-5x {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }
}

.py {
    &-auto {
        padding-top: auto !important;
        padding-bottom: auto !important;
    }
    &-0 {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    &-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    &-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    &-3 {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
    }
    &-4 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    &-5 {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
    }

    &-1x {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    &-2x {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
    &-3x {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    &-4x {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }
    &-5x {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
}

.pt {
    &-0 {
        padding-top: 0px !important;
    }
    &-1 {
        padding-top: 0.25rem !important;
    }
    &-2 {
        padding-top: 0.5rem !important;
    }
    &-3 {
        padding-top: 0.75rem !important;
    }
    &-4 {
        padding-top: 1rem !important;
    }
    &-5 {
        padding-top: 1.25rem !important;
    }

    &-1x {
        padding-top: 1rem !important;
    }
    &-2x {
        padding-top: 2rem !important;
    }
    &-3x {
        padding-top: 3rem !important;
    }
    &-4x {
        padding-top: 4rem !important;
    }
    &-5x {
        padding-top: 5rem !important;
    }
}

.pr {
    &-0 {
        padding-right: 0px !important;
    }
    &-1 {
        padding-right: 0.25rem !important;
    }
    &-2 {
        padding-right: 0.5rem !important;
    }
    &-3 {
        padding-right: 0.75rem !important;
    }
    &-4 {
        padding-right: 1rem !important;
    }
    &-5 {
        padding-right: 1.25rem !important;
    }

    &-1x {
        padding-right: 1rem !important;
    }
    &-2x {
        padding-right: 2rem !important;
    }
    &-3x {
        padding-right: 3rem !important;
    }
    &-4x {
        padding-right: 4rem !important;
    }
    &-5x {
        padding-right: 5rem !important;
    }
}

.pl {
    &-0 {
        padding-left: 0px !important;
    }
    &-1 {
        padding-left: 0.25rem !important;
    }
    &-2 {
        padding-left: 0.5rem !important;
    }
    &-3 {
        padding-left: 0.75rem !important;
    }
    &-4 {
        padding-left: 1rem !important;
    }
    &-5 {
        padding-left: 1.25rem !important;
    }

    &-1x {
        padding-left: 1rem !important;
    }
    &-2x {
        padding-left: 2rem !important;
    }
    &-3x {
        padding-left: 3rem !important;
    }
    &-4x {
        padding-left: 4rem !important;
    }
    &-5x {
        padding-left: 5rem !important;
    }
}

.pb {
    &-0 {
        padding-bottom: 0px !important;
    }
    &-1 {
        padding-bottom: 0.25rem !important;
    }
    &-2 {
        padding-bottom: 0.5rem !important;
    }
    &-3 {
        padding-bottom: 0.75rem !important;
    }
    &-4 {
        padding-bottom: 1rem !important;
    }
    &-5 {
        padding-bottom: 1.25rem !important;
    }

    &-1x {
        padding-bottom: 1rem !important;
    }
    &-2x {
        padding-bottom: 2rem !important;
    }
    &-3x {
        padding-bottom: 3rem !important;
    }
    &-4x {
        padding-bottom: 4rem !important;
    }
    &-5x {
        padding-bottom: 5rem !important;
    }
}
