@import "./variables.scss";

h3 {
	font-weight: 500;
	font-size: 1.6667rem;
	line-height: 2.6667rem;
}

h5 {
	font-weight: bold;
	font-size: 1.16667rem;
	line-height: 1.333rem;
}

.content-center {
	justify-content: center;
	align-items: center;
}

.w-100 {
	width: 100% !important;
}

.border-none {
	border: none !important;
}

.background-none {
	background: none;
}

.shadow-none {
	box-shadow: none;
}

.cursor-pointer {
	cursor: pointer;
}

/* common css */
.compensation-additional-buttons-container {
	border: none;
	background: none;
	box-shadow: none;

	.additional-buttons {
		margin-left: 3px;
	}

	button {
		width: 25px !important;
		padding-top: 6px;
		padding-bottom: 6px;
	}
}

.d-flex {
	display: flex;
	flex-direction: row;
}

.d-flex-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.d-flex-column {
	display: flex;
	flex-direction: column;
}

.fw-700 {
	font-weight: 700;
}

.h-auto {
	height: auto !important;
}

.menu-manual-create-record {
	border: 1px solid $border-color;
	padding: 0.83rem 0.5rem;
	position: fixed;

	.list-items {
		padding: 0;

		li {
			padding: 0.5rem 0.83rem;
			margin-top: 0 !important;

			a {
				padding: 0;
			}

			svg {
				max-width: 1rem;
				max-height: 1rem;
				margin-right: 0.5rem;
			}
		}

		.euiListGroupItem__label {
			font-size: 1rem;
			line-height: 1.2;
		}

		.euiListGroupItem__button {
			padding: 0;
		}
	}
}

/* font-style */
.sub-title {
	font-size: 0.75rem;
	font-weight: 600;
	color: $section-title-color;
}
.sub-header {
	font-size: 0.75rem;
	font-weight: 600;
	color: $light-color;
	margin: auto 0;
}

// Custom Scroll bar.
::-webkit-scrollbar {
	height: 4px;
	width: 4px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: #979797;
}

// disabled background
.disabled-bg {
	background: var(--bg-color);
	cursor: not-allowed;
}

// define the class name for only search pannel
.search-pannel {
	width: 400px;
	position: fixed;
}
