$count-color: #006BB4;

// Success variables
$font-size: .8;
$count-font-size: 1;

.msg-container {
  text-align: center;

  .msg {
    font-weight: bold;
    font-size: $font-size + rem;
    margin-bottom: 5px;
  }

  .count {
    font-size: $count-font-size + rem;
    font-weight: 600;
    color: $count-color;
    text-decoration: underline;
  }
}
