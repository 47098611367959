/* Variable */

/* Font Colors */
$base-color: #000000;
$primary-color: #343741;
$light-color: #98a2b3;
$success-color: #017d73;
$secondary-color: #017d73;
$warning-color: #f5a700;
$danger-color: #bd271e;
$white-color: #ffffff;

/* Border Colors */
$border-color: #d3dae6;

/* Un regular colors */
$title-color: #1a1c21;
$section-title-color: #98a2b3;
$link-color: #006bb4;
$info-color: #69707d;
$lightest-shade-color: #f5f7fa;
$badge-color: #e0e5ee;
$vis5-behind-text-color: #f1d86f;
$warning-text-color: #9b6900;

/* Button colors */
$primary-btn-color: #006bb4;
$btn-border-color: #c2c3c6;

/* Button background colors */
$hovered-btn-bg-color: #eef2f7;
$bg-color: #e9edf3;
$self-bg-color: #dbeeff;
$empty-shade-bg-color: #eaeef4;

$code-block-number-color: #00a69b;
$empower-core: #f05c22;
$screening-background: #ddedff;

/* others */
$blue-3: #56ccf2;
$orange: #f2994a;
$orange-50: #f2994a50;
$green-1: #219653;

$badge-secondary: #6dccb1;

:export {
    baseColor: $base-color;
    primaryColor: $primary-color;
    sectionTitleColor: $section-title-color;
    linkColor: $link-color;
    lightestShadeColor: $lightest-shade-color;
    badgeColor: $badge-color;

    secondaryColor: $secondary-color;
    warningColor: $warning-color;
    whiteColor: $white-color;
    titleColor: $title-color;

    borderColor: $border-color;
    lightColor: $light-color;
    successColor: $success-color;
    warningColor: $warning-color;
    dangerColor: $danger-color;
    viz5BehindText: $vis5-behind-text-color;
    warningTextColor: $warning-text-color;

    primaryBtnColor: $primary-btn-color;
    btnBorderColor: $btn-border-color;
    hoveredBtnBgColor: $hovered-btn-bg-color;
    bgColor: $bg-color;
    selfBgColor: $self-bg-color;
    emptyShadeBgColor: $empty-shade-bg-color;

    infoColor: $info-color;
    codeBlockNumberColor: $code-block-number-color;
    empowerCore: $empower-core;
    screeningColor: $screening-background;

    blue3: $blue-3;
    orange: $orange;
    orange50: $orange-50;
    green1: $green-1;

    badgeSecondary: $badge-secondary;
}
