@import "assets/css/common.scss";
@import "assets/css/variables.scss";
@import "assets/css/spacing.scss";

@font-face {
	font-family: "Inter_Regular";
	src: local("Inter_Regular"),
		url("../assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

html,
body {
	margin: 0;
	font-family: "Inter_Regular";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: -0.08px;
	font-size: 12px;
	line-height: 18px;
	color: $primary-color;
	background-color: #f5f7fa;
}
.App {
	display: flex;
	flex-direction: column;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

input,
textarea,
select,
button {
	font-family: "Inter_Regular" !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// customize the eui
.euiTableFooterCell .euiTableCellContent__text,
.euiTableHeaderCell .euiTableCellContent__text,
.euiTable {
	font-size: 1rem;
	line-height: 1.5rem;
}

.euiButtonEmpty.euiButtonEmpty--xSmall,
.euiFilterButton,
.euiFieldSearch {
	font-size: 1.2rem;
}

.euiBadge,
.euiBetaBadge {
	font-size: 1rem;
}

.euiButton {
	font-family: "Inter_Regular";
}

/* == standard rule for input, combobox, select ... == */
// input box
.euiFieldText--compressed,
.euiFieldText:not(.username-field) {
	height: 25px;
	font-size: 1rem;
	line-height: 1.5rem;
}

// EuiComboBox
.euiComboBox .euiComboBox__input,
.euiComboBox .euiComboBox__inputWrap {
	height: 25px;
	font-size: 1rem;
	line-height: 1.5rem;
}
.euiComboBoxPill,
.euiComboBoxPill + .euiComboBoxPill {
	margin: 2px;
}
.euiComboBoxPlaceholder {
	line-height: 2rem;
}
.euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap {
	padding-top: 4px;
	padding-bottom: 4px;
}
.euiComboBox--compressed .euiComboBoxPill,
.euiComboBox--compressed .euiComboBoxPill + .euiComboBoxPill {
	margin: 2px;
}

.euiComboBox .euiComboBox__inputWrap {
	padding: 0 4px;
}
.euiComboBoxPill,
.euiComboBoxPill + .euiComboBoxPill {
	font-size: 1rem;
	line-height: 1.5rem;
}
.euiComboBoxPill--plainText {
	font-family: inherit;
}
.euiComboBox .euiComboBox__inputWrap .euiComboBoxPill {
	max-width: calc(100% - 2px - 26px);
}

// EuiSelect
.euiSelect {
	height: 25px;
	font-size: 1rem;
	line-height: 1.5rem;
}

// EuiTextarea
.euiTextArea {
	font-size: 1rem;
	line-height: 1.5rem;
}

// EuiSearch EuiButtonEmpty for small
.euiFieldSearch,
.euiFieldSearch.euiFieldSearch--compressed,
.euiButtonEmpty.euiButtonEmpty--small,
.euiButton.euiButton--small {
	height: 28px;
	font-size: 1.2rem;
}

// EuiText, EuiComboBox Options
.euiText--small,
.euiComboBoxOption__content {
	font-size: 1rem;
}

// Small / Paragraph -> Default
.euiTitle--small,
.euiContextMenuItem__text,
.euiButton__text,
.euiButtonEmpty__text,
.euiTab__content {
	font-size: var(--sp-7);
}

// Badge, activities, form label sub-text default sizes
.euiBetaBadge,
.euiFormLabel,
.euiText--extraSmall {
	font-size: 0.875rem;
}

.euiFormRow__label {
	font-size: 1rem;
	font-weight: 600;
}

.euiText--small > h3 {
	font-size: var(--sp-7);
	font-weight: 600;
}

.euiText--medium,
.flex-item-label--medium {
	font-size: var(--sp-8);
}

.euiFlyout {
	top: 0;
	margin-top: 88px;
	padding-bottom: 90px;
	z-index: 1000;
}

.euiFlyoutHeader--hasBorder {
	padding-bottom: 12px;
	border-bottom: 1px solid #d3dae6;
	padding-top: 14px;
}

.euiAccordion.euiAccordion-isOpen .euiAccordion__childWrapper {
	padding-top: 0.5rem;
}

.PhoneInputCountry {
	background: #ffffff;
	border: 1px solid #d3dae6;
	border-right: 0;
	box-sizing: border-box;
	border-radius: 3px 0px 0px 3px;
	padding: 4px;
	height: 25px;
	margin-right: 0px !important;
}

.PhoneInputInput,
.edit-display--phone-number {
	padding: 4px;
	height: 25px;
	border: 1px solid #d3dae6;
	box-sizing: border-box;
	border-radius: 0px 3px 3px 0px;
}

.phone-extension {
	display: flex;
	margin-left: 42px;

	& > .edit-display--phone-number {
		border-radius: 3px 3px 3px 3px;
	}

	.euiFlexItem.euiFlexItem--flexGrowZero:last-of-type {
		margin-top: -2px;
	}
}

.PhoneInputInput {
	width: 120px;
}

.visibilitity-hidden {
	visibility: hidden;
}

.position-relative {
	position: relative;
}

.euiToolTip--bottom {
	margin-top: -10px;
	margin-left: 1.25px;

	&.back-white {
		background-color: #ffffff;
		color: $primary-color;
		font-size: 1rem;
		line-height: 1.5;
		border: 1px solid #d3dae6;
		box-sizing: border-box;

		box-shadow: 0px 12px 24px rgba(65, 78, 101, 0.1),
			0px 6px 12px rgba(65, 78, 101, 0.1), 0px 4px 4px rgba(65, 78, 101, 0.1),
			0px 2px 2px rgba(65, 78, 101, 0.1);
		border-radius: 4px;

		.euiToolTip__arrow {
			background-color: #ffffff;
			border-top: 1px solid #d3dae6;
			border-left: 1px solid #d3dae6;
			margin-top: -2px;
		}
	}
}

.react-datepicker-popper {
	z-index: 9999 !important;
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	border: 1px solid #d3dae6;
	background-color: #fff;
	border-radius: 0 0 4px 4px;
}

.euiPage {
	width: 100%;
}

.euiPage:not(#login-page) {
	margin-top: 86px;
}

.icon-wrapper {
	height: 16px;
	width: 16px;
}

.euiTab__content {
	font-size: 1rem;
}

.icon {
	&-text {
		height: 20px;

		& span.euiButtonEmpty__text {
			margin-top: -5px;
			font-size: 1rem;
		}
	}

	&-button {
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;
		height: 20px;
		width: 20px;
		margin-right: 4px;
		margin-top: 1px;
	}

	&-styles {
		padding: 2px;
		width: 22px !important;
		height: 25px;
		box-sizing: border-box;
		border-radius: 2px;
		background: #ffffff;
		border: 0.5px solid #d3dae6;

		&:hover {
			background: #eef2f7;
		}
	}
}

.button-icon-wrapper {
	background: #ffffff;
	border: 1px solid #d3dae6;
	box-sizing: border-box;
	border-radius: 3px;
	margin-top: 2.5px;
}

.euiSuperSelect__listbox {
	width: auto !important;
}
.euiPopover__panel {
	min-width: 66px;
	left: 35px;
}

.enrichment-popover-panel {
	width: 274px;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.euiToolTipPopover {
	background-color: #ffffff;
	.euiToolTip__arrow {
		background-color: #ffffff;
	}
	color: $primary-color;
}

.euiPopover__panel {
	.euiPopover__panelArrow--right,
	.euiPopover__panelArrow--left {
		top: 4px !important;
	}
}

// tinymce style
.tox .tox-tbtn {
	transform: scale(0.7) !important; /* you can set the scale that you want */
	height: unset !important;
	width: unset !important;
}
.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
	width: unset !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
	/* change the background y-positionning to : bottom 0*/
	background: url("data:image/svg+xml;charset=utf8,%3Csvg height='39px' viewBox='0 0 40 39px' width='40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='38px' width='100' height='1' fill='%23cccccc'/%3E%3C/svg%3E")
		left 0 bottom 0 #fff !important;
}
